import Event1 from "../../assets/images/event-1.jpg";
import Event2 from "../../assets/images/event-2.jpg";
import Event3 from "../../assets/images/event-3.jpg";
import Event4 from "../../assets/images/event-4.jpg";
import Event5 from "../../assets/images/event-5.jpg";

// Contacts: [
//     { name: 'Sujana Achanta', phone: '630 248 5823', email: 'rao@gmail.com' },
//     { name: 'Rani Vege', phone: '847 725 9180', email: 'rao@gmail.com' }
// ],
const EventsData = [
    {
        id: 1,
        image: Event1,
        Title: 'Diwali Celebrations',
        Date: 'Saturday, October 18, 2025',
        Time: '5:00 PM - 09:00 PM',
        Location: 'Balaji Temple Auditorium Aurora, IL USA',
        Contacts: [],
        teamEmail: 'cultural@chicagoteluguassociation.org',
        flyerUrl:''
    },
    {
        id: 2,
        image: Event2,
        Title: 'Ugadi Srirama Navami Celebrations',
        Date: 'Saturday, April 05, 2025',
        Time: '5:00 PM - 09:00 PM',
        Location: 'Balaji Temple Auditorium Aurora, IL USA',
        Contacts: [],
        teamEmail: 'cultural@chicagoteluguassociation.org',
        flyerUrl:'https://aogmedia.s3.amazonaws.com/CouponWebsiteAssets/CTA-Ugadi-2025.pdf'
    },
    {
        id: 3,
        image: Event3,
        Title: 'Ugadi Srirama Navami Celebrations',
        Date: 'Saturday, April 05, 2025',
        Time: '5:00 PM - 09:00 PM',
        Location: 'Balaji Temple Auditorium Aurora, IL USA',
        Contacts: [],
        teamEmail: 'cultural@chicagoteluguassociation.org',
        flyerUrl:'https://aogmedia.s3.amazonaws.com/CouponWebsiteAssets/CTA-Ugadi-2025.pdf'
    },
    {
        id: 4,
        image: Event4,
        Title: 'FMSA Drive',
        Date: 'Friday, Febraury 16, 2019',
        Time: '5:00 PM - 8:00 PM',
        Location: 'Balaji Temple Auditorium Aurora, IL USA',
        Contacts: [],
        teamEmail: 'cultural@chicagoteluguassociation.org',
        flyerUrl:''
    },
    {
        id: 5,
        image: Event5,
        Title: 'Telugu Festival',
        Date: 'Thusday, July 02, 2009',
        Time: '5:00 PM - 8:00 PM',
        Location: 'Odeum Expo Center Villa Park, IL',
        Contacts: [],
        teamEmail: 'cultural@chicagoteluguassociation.org',
        flyerUrl:''
    }
];

export default EventsData;
